@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sign-in-container{
    height: 88vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

    .sign-in-popup{
        width: 50%;
        height: 60vh;
        background-color: rgb(41, 39, 39);
        border-radius: 20px;
        padding-top: 2rem;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    }

        .sign-in-popup h1,
        .sign-in-popup h4{
            color: #fff;
        }

        .form-sign-in{
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

            .form-sign-in button{
                margin-top: 1rem;
                padding: 8px 20px;
                border: none;
                border-radius: 10px;
                color: #fff;
                font-weight: bold;
                font-family: "Lato", sans-serif;
                font-size: 18px;
                letter-spacing: 1px;
                background-color: rgb(65, 65, 65);
                cursor: pointer;
            }

            .form-sign-in button:focus{
                outline: none;
            }


            .form-field{
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
            }

                .form-field label{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: #fff;
                    font-weight: bold;
                    font-size: 20px;
                    font-family: "Lato", sans-serif;
                    letter-spacing: 1px;
                    white-space: nowrap;
                    padding-bottom: 10px;
                }

                .form-field input{
                    height: 30px;
                    border: none;
                    padding: 5px 10px;
                    border-radius: 5px;
                }

                    .form-field input:focus{
                        outline: none;
                    }

        .error-message{
            color: #fff;
            margin-bottom: 20px;
        }

        .forgot-first{
            display: flex;
            flex-direction: column;
        }

        .forgot-pass{
            background-color: transparent;
            margin: 0 auto;
            margin-bottom: 10px;
            width: 15%;
            color: #fff;
            border: none;
            white-space: nowrap;
            cursor: pointer;
        }   

            .forgot-pass:focus{
                outline: none;
            } 

.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}

    .modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
        margin: 0 auto;
        padding: 40px 20px;
        background: #fff;
        border-radius: 10px;
        text-align: center;
    }

        .modal p{
            margin-top: 1rem;
            font-weight: bold;
        }

        .modal button{
        color: #444;
        border-color: #444;
        font-weight: bold;
        margin-top: 20px;
        }
    
        .modal h4{
        color: #444;
        font-weight: bold;
        padding-bottom: 5px;
        }

        .steps{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            width: 100%;
        }

        .reset-inputs
        {
            margin-top: 1rem;
            width: 70%;
            border: none;
            height: 30px;
            font-weight: bold;
            border-radius: 5px;
            padding: 5px 10px;
            box-shadow: 1px 1px 4px rgba(10, 10, 10, 0.7);
        }

            .reset-inputs:focus
            {
                outline: none;
            }

        .pass-reset-btns{
            padding: 10px 20px;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

            .pass-reset-btns:focus{
                outline: none;
            }

@media only screen and (max-width: 768px) {
    .sign-in-popup{
        width: 90%;
        height: 80%;
    }

    .form-field{
        width: 90%;
    }

    .form-sign-in{
        height: 60%;
    }

    .form-sign-in button{
        width: 90%;
        margin-bottom: 1rem;
    }

    .forgot-pass{
        width: 100%;
        margin-bottom: 20px;
    }
}
/* .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  } */
  
  .user-navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem auto;
    width: 80%;
    background-color: rgb(41, 39, 39);
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
    min-height: 30px;
  }
  
    .user-navbar-options button{
      color: #fff;
      background-color: transparent;
      border: none;
      font-weight: bold;
      margin-left: 20px;
      font-size: 14px;
    }
  
      .user-navbar-options button:hover{
        font-size: 15px;
      }
  
      .user-navbar-options button:focus{
        outline: none;
      }
  
  .welcome-user{
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }
  
    .welcome-user h4{
      text-align: right;
      padding-right: 10px;
    }
  
    .welcome-user button{
      background-color: transparent;
      font-weight: bold;
      font-size: 14px;
      border: none;
      color: #fff;
      cursor: pointer;
    }
  
      .welcome-user button:hover{
        font-size: 15px;
      }
  
      .welcome-user button:focus{
        outline: none;
      }
  
  .customer-container{
    padding: 1.5rem 0;
    margin: 0 auto;
    margin-bottom: 1rem;
    width: 80%;
    background-color: rgb(41, 39, 39);
    border-radius: 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  }
  
    .section-name{
      color: #fff;
      margin-bottom: 2rem;
    }
  
  .header,
  .event,
  .product{
    margin: 0 auto 2rem auto;
    width: 90%;
    background-color: rgb(49, 64, 80);
    display: flex;
    padding: 1rem;
    /* min-height: 200px; */
    height: 100%;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  }

  .headersec{
    display: flex;
    width: 99%;
    margin: auto;
  }

  .headerimg{
    flex: 1 1;
    margin-right: 5px;
  }

  .headeredit{
    flex: 2 1;
  }

  .eventinner,
  .productinner{
    display: flex;
    width: 100%;
  }

  .Collapsible{
    width: 100%;
  }

  .is-open{
    color: #fff;
    display: inline-block;
    margin-bottom: 2rem;
    width: 100%;
  }

  .is-closed{
    color: #fff;
    display: inline-block;
    width: 100%;
  }

  .collapseDiv{
    width: 100%;
    height: 100%;
  }
  
  .header{
    width: 500px;
    min-height: 700px;
  }
  
  .add-edit-container{
    margin: 0 auto 2rem auto;
    width: 85%;
    background-color: rgb(49, 64, 80);
    display: flex;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  }
    .add-edit-container h3{
      text-align: start;
      color: #fff;
    }
  
    .add-btn{
      display: flex;
      justify-content: flex-end;
    }
  
      .add-btn button{
        padding: 0.5rem 2rem;
        margin-top: 1rem;
        border-radius: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        background-color: #437c5f;
        border: none;
        cursor: pointer;
      }

        .add-btn button:hover{
          background-color: #30a76b;
        }

        .add-btn button:focus{
          outline: none;
        }
  
  .main{
    width: 100%;
    border-radius: 10px;
  }
  
  .app-banner{
    background-color: #fff;
  }
  
    .app-banner h1{
      color: #000;
    }
  
  .main-content{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  
    .ht{
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.596);
    }
  
    .ht2{
      margin: 0;
    }
  
    .ht3{
      margin-bottom: 4rem;
    }
  
    .header-btn{
      padding: 0.8rem 2rem;
      margin: 0 auto;
      background-color: #b60000;
      color: #fff;
      font-size: 20px;
      border: none;
      border-radius: 10px;
    }
      .header-btn:focus{
        outline: none;
      }
  
  .image-div{
    display: flex;
    justify-content: center;
    align-self: flex-start;
    flex: 2 1;
    max-height: 80%;
  }
    .image-div img{
      width: 100%;
      border-radius: 10px;
    }
  
  .details-div{
    flex: 3 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 1rem;
    color: #fff;
  }
  
  .dd-top{
    width: 100%;
    flex: 80% 1;
  }
  
    .dd-top h1{
      color: #fff;
    }
  
    .dd-top h3{
      text-align: left;
      margin-bottom: 5px;
    }
  
    .dd-top input,
    .dd-top textarea{
      height: 30px;
      margin-bottom: 0.8rem;
      padding-left: 0.3rem;
      width: 100%;
      font-weight: bold;
      border-radius: 10px;
      border: none;
    }

    .dd-top textarea{
      height: 100px;
    }
  
  .dd-bottom{
    flex: 20% 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .dd-bottom button{
    padding: 0.5rem 2rem;
    margin-top: 1rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #912929;
    border: none;
    cursor: pointer;
  }

  .archiveBtn2{
    background-color: #437c5f !important;
  }
  
    .dd-bottom button:hover{
      background-color: #e93131;
    }

    .archiveBtn2:hover{
      background-color: #60b187 !important;
    }

    .dd-bottom button:focus,
    .dd-top input:focus,
    .dd-top textarea:focus,
    .archiveBtn2:focus
    {
      outline: none;
    }

  
  
  .send-new-list-btn{
    padding: 1rem 3rem;
    margin-bottom: 2rem;
    border: none;
    background-color: #437c5f;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  }

    .send-new-list-btn:hover{
      background-color: #30a76b;
    }

    .send-new-list-btn:focus{
      outline: none;
    }
  
  .post-message{
    color: #fff;
    margin-bottom: 1rem;
  }
  
    .app-info{
      flex: 85% 1;
    }
  
    .header-edit{
      display: flex;
    }
  
      .header-edit-left{
        flex: 85% 1;
      }
  
      .header-edit-right{
        flex: 15% 1;
        display: flex;
        align-items: top;
      }
  
        .header-edit-right input{
          width: 60%;
          text-align: center;
        }
        
        .header-edit-right button{
          width: 20%;
          height: 30px;
          border-radius: 10px;
          border: none;
          margin: 0 5px;
          cursor: pointer;
        }
  
          .header-edit-right button:focus{
            outline: none;
          }
  
  .customer-sign-up-page{
    min-height: 100vh;
  }
  
    #datePickerSearch{
      border: none;
      width: 20%;
      padding: 2px 5px;
    }
      #datePickerSearch:focus{
        outline: none;
      }
  
    .date-picker button{
      padding: 3px 10px;
      margin-left: 8px;
      font-weight: bold;
    }
  
      .date-picker button:focus{
        outline: none;
      }
  
    .data-viewer h2{
      margin: 4rem 0 2rem 0;
      color: #fff;
    }
  
    .data-viewer button{
      margin-top: 2rem;
      padding: 10px 20px;
      background-color: #30a76b;
      border: none;
      font-weight: bold;
      font-size: 16px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
  
      .data-viewer button:focus{
        outline: none;
      }
  
      .customer-event-area{
        display: flex;
        justify-content: space-evenly;
        margin: 1rem auto;
        width: 50%;
        color: #fff;
      } 
  
        .customer-event-area p{
          text-align: start;
          width: 100%;
        }


@media only screen and (max-width: 1024px) {
  .headersec{
    display: block;
  }

  .headerimg{
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .header-edit{
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .user-navbar{
    flex-direction: column;
    justify-content: space-between;
    min-height: 60px;
    width: 100%;
  }

  .customer-container{
    width: 100%;
  }

  .header{
    width: 100%;
    min-height: 700px;
  }

  .event,
  .product{
    flex-direction: column;
    width: 96%;
  }

    .image-div{
      margin-bottom: 1rem;
    }

    .details-div{
      padding: 0;
    }

    .eventinner,
    .productinner{
      flex-direction: column;
      width: 100%;
    }

  .add-edit-container{
    width: 96%;
  }

  .section-name{
    font-size: 1.5rem;
  }

  #datePickerSearch{
    width: 70%;
  }

  .customer-event-area{
    flex-direction: column;
  } 
}


        
.home-page-container{
    height: 88vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .home-header-container{
        width: 90%;
        display: flex;
    }

        .home-header-left{
            flex: 50% 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            color: #fff;
        }

            .home-header-left h3{
                font-size: 1.2rem;
                margin-bottom: 1rem;
                font-family: "Roboto", sans-serif;
            }

            .home-header-left h1{
                margin-bottom: 2rem;
                font-size: 4.5rem;
                text-align: start;
                font-family: "Roboto", sans-serif;
            }

            .home-header-left p{
                width: 100%;
                font-size: 1rem;
                text-align: left;
                line-height: 1.5;
                font-family: "Roboto", sans-serif;
            }

            .home-header-left button{
                margin-top: 1rem;
                padding: 10px 20px;
                background-color: cadetblue;
                border: none;
                border-radius: 20px;
                font-size: 0.9rem;
                color: #fff;
                font-family: "Roboto", sans-serif;
                cursor: pointer;
            }

                .home-header-left button:hover{
                    background-color: rgb(127, 198, 201);
                    color: rgb(20, 20, 20);
                    font-weight: bold;
                }

                .home-header-left button:focus{
                    outline: none;
                }

        .home-header-right{
            flex: 50% 1;
            background-image: url(../../static/media/HeaderImage.1f56e1f6.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

@media only screen and (max-width: 1200px) {
    .home-header-left h3{
        text-align: left;
        font-size: 1.2rem;
    }

    .home-header-left h1{
        margin-bottom: 1rem;
        font-size: 3.2rem;
    }

    .home-header-left p{
        font-size: 1rem;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 1005px) {
    .home-header-left h1{
        margin-bottom: 1rem;
        font-size: 2rem;
    }

    .home-header-left p{
        font-size: 1rem;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 768px) {

    .container{
        height: 75vh;
    }

    .home-header-container{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .home-header-left button{
        margin: 1rem 0 0 0;
    }

    .home-header-right{
        background-position: top;
        height: 200px;
    }
}

@media only screen and (max-width: 375px) {

    .home-page-container{
        height: 100vh;
        margin-top: 1rem;
    }

    .home-header-left h1{
        margin-bottom: 0.8rem;
        font-size: rem;
    }

    .home-header-left p{
        font-size: 1rem;
    }

}
.about-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #about-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        font-family: "Roboto", sans-serif;
    }

    .about-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .about-section h2{
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;

        }

        .about-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

        .about-langs{
            width: 60%;
            margin: 1rem auto;
            display: flex;
            justify-content: space-between;
        }

            .about-langs ul li{
                list-style: none;
                padding-bottom: 6px;
                font-size: 1.2rem;
                font-weight: bold;
                font-family: "Roboto", sans-serif;
            }

    .about-section-btns{
        width: 80%;
        margin: 1rem auto 0 auto;
        display: flex;
        justify-content: space-between;
    }
        
        .about-section-btns button{
            margin-top: 1rem;
            padding: 10px 20px;
            background-color: cadetblue;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            color: #fff;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }

            .about-section-btns button:hover{
                background-color: rgb(127, 198, 201);
                color: rgb(20, 20, 20);
                font-weight: bold;
            }

            .about-section-btns button:focus{
                outline: none;
            }

@media only screen and (max-width: 968px) {
    .about-langs{
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .about-section{
        width: 95%;
    }

    .about-section-btns{
        display: block;
    }

    .about-section-btns button{
        width: 100%;
    }

    .about-langs{
        width: 100%;
    }

        .about-langs ul li{
            font-size: 1.1rem;
        }
}
.contact-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #contact-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        font-family: "Roboto", sans-serif;
    }

    .contact-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .contact-section h2{
            font-size: 3rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;
        }

        .contact-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

        .contact-section div{
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            width: 100%;
        }

        .contact-section input,
        .contact-section textarea{
            border: none;
            margin: 10px auto;
            height: 40px;
            border-radius: 10px;
            padding: 5px 10px;
            width: 80%;
            vertical-align: middle;
        }

        #contact-message{
            height: 100px;
        }

            .contact-section input:focus,
            .contact-section textarea:focus{
                outline: none;
            }

        #contact-btn{
            margin: 1rem auto 0 auto;
            padding: 10px 20px;
            width: 80%;
            background-color: cadetblue;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            color: #fff;
            letter-spacing: 1.2px;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }

            #contact-btn:hover{
                background-color: rgb(127, 198, 201);
                color: rgb(20, 20, 20);
                font-weight: bold;
            }

            #contact-btn:focus{
                outline: none;
            }

        .contact-quote-message{
            width: 100%;
            text-align: center;
        }

@media only screen and (max-width: 768px) {
    .contact-section{
        width: 95%;
        padding: 1rem;
    }

    .contact-section h2{
        font-size: 2.3rem;
    }

    .contact-section input,
    .contact-section textarea{
        width: 100%;
    }

    .contact-section-btns{
        display: block;
    }

    .contact-section-btns button{
        width: 100%;
    }
}
.quote-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #quote-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        font-family: "Roboto", sans-serif;
    }

    .quote-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .quote-section h2{
            font-size: 2.5rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;
        }

        .quote-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

        .quote-section div{
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            width: 100%;
        }

        .quote-section input,
        .quote-section textarea,
        .quote-section select{
            border: none;
            margin: 10px auto;
            height: 40px;
            border-radius: 10px;
            padding: 5px 10px;
            width: 80%;
            vertical-align: middle;
        }

        .quote-section select option{
            font-size: 16px;
        }

        #quote-message{
            height: 100px;
        }

            .quote-section input:focus,
            .quote-section textarea:focus,
            .quote-section select:focus{
                outline: none;
            }

        #quote-btn{
            margin: 1rem auto 0 auto;
            padding: 10px 20px;
            width: 80%;
            background-color: cadetblue;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            color: #fff;
            letter-spacing: 1.2px;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }

            #quote-btn:hover{
                background-color: rgb(127, 198, 201);
                color: rgb(20, 20, 20);
                font-weight: bold;
            }

            #quote-btn:focus{
                outline: none;
            }

@media only screen and (max-width: 945px) {
    .quote-section h2{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .quote-section{
        width: 95%;
        padding: 1rem;
    }

    .quote-section h2{
        font-size: 2.3rem;
    }

    .quote-section input,
    .quote-section textarea,
    .quote-section select{
        width: 100%;
    }

    .quote-section-btns{
        display: block;
    }

    .quote-section-btns button{
        width: 100%;
    }
}
.portfolio-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #portfolio-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        letter-spacing: 1.2px;
        font-family: "Roboto", sans-serif;
    }

    .portfolio-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .portfolio-section h2{
            font-size: 2.5rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;
        }

        .portfolio-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

    .portfolio-loop{
        width: 100%;
        margin: 2rem 0;
    }

        .grid-container {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(auto-fill, 250px);
            grid-gap: 30px 30px;
            padding: 10px;
            justify-content: center;
        }
            .grid-item {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }

@media only screen and (max-width: 945px) {
    .portfolio-section h2{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .portfolio-section{
        width: 95%;
        padding: 1rem;
    }

    .portfolio-section h2{
        font-size: 2.3rem;
    }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body{
  background-color: rgb(41, 39, 39);
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0.8rem;
}

body::-webkit-scrollbar-thumb {
  background-color: #181818;
  outline: none;
}

.App {
  min-height: 100vh;
  text-align: center;
  position: relative;
}

  #contact-link{
    color: #fff;
  }

  .bg-image{
    position: fixed;
    z-index: -100;
    min-height: 100vh;
    min-width: 100vw;
    background-image: url(../../static/media/HeaderBGJustColors-1024x576.76819438.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .app-footer{
    margin-top: 1rem;
    font-weight: normal;
    color: #fff;
    min-height: 22px;
    max-height: 22px;
  }


.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10rem;
}

    .solo-app-lab{
        white-space: nowrap;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        font-family: "Roboto", sans-serif;
    }

    .nav ul{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

        .nav li{
            list-style: none;
            margin: 0 1rem;
            white-space: nowrap;
        }

            .nav-links{
                color: #fff;
                text-decoration: none;
                font-weight: bold;
                font-family: "Roboto", sans-serif;
            }

@media only screen and (max-width: 830px) {
    .nav{
        padding: 1rem 1rem;
    }
    
    .nav ul{
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .nav{
        display: block;
    }

    .nav ul{
        display: block;
    }

    .nav ul li{
        margin: 5px 0;
    }
}
