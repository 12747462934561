.quote-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #quote-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        font-family: "Roboto", sans-serif;
    }

    .quote-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .quote-section h2{
            font-size: 2.5rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;
        }

        .quote-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

        .quote-section div{
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            width: 100%;
        }

        .quote-section input,
        .quote-section textarea,
        .quote-section select{
            border: none;
            margin: 10px auto;
            height: 40px;
            border-radius: 10px;
            padding: 5px 10px;
            width: 80%;
            vertical-align: middle;
        }

        .quote-section select option{
            font-size: 16px;
        }

        #quote-message{
            height: 100px;
        }

            .quote-section input:focus,
            .quote-section textarea:focus,
            .quote-section select:focus{
                outline: none;
            }

        #quote-btn{
            margin: 1rem auto 0 auto;
            padding: 10px 20px;
            width: 80%;
            background-color: cadetblue;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            color: #fff;
            letter-spacing: 1.2px;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }

            #quote-btn:hover{
                background-color: rgb(127, 198, 201);
                color: rgb(20, 20, 20);
                font-weight: bold;
            }

            #quote-btn:focus{
                outline: none;
            }

@media only screen and (max-width: 945px) {
    .quote-section h2{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .quote-section{
        width: 95%;
        padding: 1rem;
    }

    .quote-section h2{
        font-size: 2.3rem;
    }

    .quote-section input,
    .quote-section textarea,
    .quote-section select{
        width: 100%;
    }

    .quote-section-btns{
        display: block;
    }

    .quote-section-btns button{
        width: 100%;
    }
}