@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body{
  background-color: rgb(41, 39, 39);
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 0.8rem;
}

body::-webkit-scrollbar-thumb {
  background-color: #181818;
  outline: none;
}

.App {
  min-height: 100vh;
  text-align: center;
  position: relative;
}

  #contact-link{
    color: #fff;
  }

  .bg-image{
    position: fixed;
    z-index: -100;
    min-height: 100vh;
    min-width: 100vw;
    background-image: url("./imgs/HeaderBGJustColors-1024x576.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .app-footer{
    margin-top: 1rem;
    font-weight: normal;
    color: #fff;
    min-height: 22px;
    max-height: 22px;
  }

