.home-page-container{
    height: 88vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    .home-header-container{
        width: 90%;
        display: flex;
    }

        .home-header-left{
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            color: #fff;
        }

            .home-header-left h3{
                font-size: 1.2rem;
                margin-bottom: 1rem;
                font-family: "Roboto", sans-serif;
            }

            .home-header-left h1{
                margin-bottom: 2rem;
                font-size: 4.5rem;
                text-align: start;
                font-family: "Roboto", sans-serif;
            }

            .home-header-left p{
                width: 100%;
                font-size: 1rem;
                text-align: left;
                line-height: 1.5;
                font-family: "Roboto", sans-serif;
            }

            .home-header-left button{
                margin-top: 1rem;
                padding: 10px 20px;
                background-color: cadetblue;
                border: none;
                border-radius: 20px;
                font-size: 0.9rem;
                color: #fff;
                font-family: "Roboto", sans-serif;
                cursor: pointer;
            }

                .home-header-left button:hover{
                    background-color: rgb(127, 198, 201);
                    color: rgb(20, 20, 20);
                    font-weight: bold;
                }

                .home-header-left button:focus{
                    outline: none;
                }

        .home-header-right{
            flex: 50%;
            background-image: url("../imgs/HeaderImage.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

@media only screen and (max-width: 1200px) {
    .home-header-left h3{
        text-align: left;
        font-size: 1.2rem;
    }

    .home-header-left h1{
        margin-bottom: 1rem;
        font-size: 3.2rem;
    }

    .home-header-left p{
        font-size: 1rem;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 1005px) {
    .home-header-left h1{
        margin-bottom: 1rem;
        font-size: 2rem;
    }

    .home-header-left p{
        font-size: 1rem;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 768px) {

    .container{
        height: 75vh;
    }

    .home-header-container{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .home-header-left button{
        margin: 1rem 0 0 0;
    }

    .home-header-right{
        background-position: top;
        height: 200px;
    }
}

@media only screen and (max-width: 375px) {

    .home-page-container{
        height: 100vh;
        margin-top: 1rem;
    }

    .home-header-left h1{
        margin-bottom: 0.8rem;
        font-size: rem;
    }

    .home-header-left p{
        font-size: 1rem;
    }

}