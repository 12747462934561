.about-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #about-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        font-family: "Roboto", sans-serif;
    }

    .about-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .about-section h2{
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;

        }

        .about-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

        .about-langs{
            width: 60%;
            margin: 1rem auto;
            display: flex;
            justify-content: space-between;
        }

            .about-langs ul li{
                list-style: none;
                padding-bottom: 6px;
                font-size: 1.2rem;
                font-weight: bold;
                font-family: "Roboto", sans-serif;
            }

    .about-section-btns{
        width: 80%;
        margin: 1rem auto 0 auto;
        display: flex;
        justify-content: space-between;
    }
        
        .about-section-btns button{
            margin-top: 1rem;
            padding: 10px 20px;
            background-color: cadetblue;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            color: #fff;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }

            .about-section-btns button:hover{
                background-color: rgb(127, 198, 201);
                color: rgb(20, 20, 20);
                font-weight: bold;
            }

            .about-section-btns button:focus{
                outline: none;
            }

@media only screen and (max-width: 968px) {
    .about-langs{
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .about-section{
        width: 95%;
    }

    .about-section-btns{
        display: block;
    }

    .about-section-btns button{
        width: 100%;
    }

    .about-langs{
        width: 100%;
    }

        .about-langs ul li{
            font-size: 1.1rem;
        }
}