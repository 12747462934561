.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10rem;
}

    .solo-app-lab{
        white-space: nowrap;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        font-family: "Roboto", sans-serif;
    }

    .nav ul{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

        .nav li{
            list-style: none;
            margin: 0 1rem;
            white-space: nowrap;
        }

            .nav-links{
                color: #fff;
                text-decoration: none;
                font-weight: bold;
                font-family: "Roboto", sans-serif;
            }

@media only screen and (max-width: 830px) {
    .nav{
        padding: 1rem 1rem;
    }
    
    .nav ul{
        justify-content: center;
    }
}

@media only screen and (max-width: 600px) {
    .nav{
        display: block;
    }

    .nav ul{
        display: block;
    }

    .nav ul li{
        margin: 5px 0;
    }
}