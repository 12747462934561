/* .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  } */
  
  .user-navbar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem auto;
    width: 80%;
    background-color: rgb(41, 39, 39);
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
    min-height: 30px;
  }
  
    .user-navbar-options button{
      color: #fff;
      background-color: transparent;
      border: none;
      font-weight: bold;
      margin-left: 20px;
      font-size: 14px;
    }
  
      .user-navbar-options button:hover{
        font-size: 15px;
      }
  
      .user-navbar-options button:focus{
        outline: none;
      }
  
  .welcome-user{
    display: flex;
    justify-content: flex-end;
    color: #fff;
  }
  
    .welcome-user h4{
      text-align: right;
      padding-right: 10px;
    }
  
    .welcome-user button{
      background-color: transparent;
      font-weight: bold;
      font-size: 14px;
      border: none;
      color: #fff;
      cursor: pointer;
    }
  
      .welcome-user button:hover{
        font-size: 15px;
      }
  
      .welcome-user button:focus{
        outline: none;
      }
  
  .customer-container{
    padding: 1.5rem 0;
    margin: 0 auto;
    margin-bottom: 1rem;
    width: 80%;
    background-color: rgb(41, 39, 39);
    border-radius: 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  }
  
    .section-name{
      color: #fff;
      margin-bottom: 2rem;
    }
  
  .header,
  .event,
  .product{
    margin: 0 auto 2rem auto;
    width: 90%;
    background-color: rgb(49, 64, 80);
    display: flex;
    padding: 1rem;
    /* min-height: 200px; */
    height: 100%;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  }

  .headersec{
    display: flex;
    width: 99%;
    margin: auto;
  }

  .headerimg{
    flex: 1;
    margin-right: 5px;
  }

  .headeredit{
    flex: 2;
  }

  .eventinner,
  .productinner{
    display: flex;
    width: 100%;
  }

  .Collapsible{
    width: 100%;
  }

  .is-open{
    color: #fff;
    display: inline-block;
    margin-bottom: 2rem;
    width: 100%;
  }

  .is-closed{
    color: #fff;
    display: inline-block;
    width: 100%;
  }

  .collapseDiv{
    width: 100%;
    height: 100%;
  }
  
  .header{
    width: 500px;
    min-height: 700px;
  }
  
  .add-edit-container{
    margin: 0 auto 2rem auto;
    width: 85%;
    background-color: rgb(49, 64, 80);
    display: flex;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
  }
    .add-edit-container h3{
      text-align: start;
      color: #fff;
    }
  
    .add-btn{
      display: flex;
      justify-content: flex-end;
    }
  
      .add-btn button{
        padding: 0.5rem 2rem;
        margin-top: 1rem;
        border-radius: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        background-color: #437c5f;
        border: none;
        cursor: pointer;
      }

        .add-btn button:hover{
          background-color: #30a76b;
        }

        .add-btn button:focus{
          outline: none;
        }
  
  .main{
    width: 100%;
    border-radius: 10px;
  }
  
  .app-banner{
    background-color: #fff;
  }
  
    .app-banner h1{
      color: #000;
    }
  
  .main-content{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  
    .ht{
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.596);
    }
  
    .ht2{
      margin: 0;
    }
  
    .ht3{
      margin-bottom: 4rem;
    }
  
    .header-btn{
      padding: 0.8rem 2rem;
      margin: 0 auto;
      background-color: #b60000;
      color: #fff;
      font-size: 20px;
      border: none;
      border-radius: 10px;
    }
      .header-btn:focus{
        outline: none;
      }
  
  .image-div{
    display: flex;
    justify-content: center;
    align-self: flex-start;
    flex: 2;
    max-height: 80%;
  }
    .image-div img{
      width: 100%;
      border-radius: 10px;
    }
  
  .details-div{
    flex: 3;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 1rem;
    color: #fff;
  }
  
  .dd-top{
    width: 100%;
    flex: 80%;
  }
  
    .dd-top h1{
      color: #fff;
    }
  
    .dd-top h3{
      text-align: left;
      margin-bottom: 5px;
    }
  
    .dd-top input,
    .dd-top textarea{
      height: 30px;
      margin-bottom: 0.8rem;
      padding-left: 0.3rem;
      width: 100%;
      font-weight: bold;
      border-radius: 10px;
      border: none;
    }

    .dd-top textarea{
      height: 100px;
    }
  
  .dd-bottom{
    flex: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .dd-bottom button{
    padding: 0.5rem 2rem;
    margin-top: 1rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #912929;
    border: none;
    cursor: pointer;
  }

  .archiveBtn2{
    background-color: #437c5f !important;
  }
  
    .dd-bottom button:hover{
      background-color: #e93131;
    }

    .archiveBtn2:hover{
      background-color: #60b187 !important;
    }

    .dd-bottom button:focus,
    .dd-top input:focus,
    .dd-top textarea:focus,
    .archiveBtn2:focus
    {
      outline: none;
    }

  
  
  .send-new-list-btn{
    padding: 1rem 3rem;
    margin-bottom: 2rem;
    border: none;
    background-color: #437c5f;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  }

    .send-new-list-btn:hover{
      background-color: #30a76b;
    }

    .send-new-list-btn:focus{
      outline: none;
    }
  
  .post-message{
    color: #fff;
    margin-bottom: 1rem;
  }
  
    .app-info{
      flex: 85%;
    }
  
    .header-edit{
      display: flex;
    }
  
      .header-edit-left{
        flex: 85%;
      }
  
      .header-edit-right{
        flex: 15%;
        display: flex;
        align-items: top;
      }
  
        .header-edit-right input{
          width: 60%;
          text-align: center;
        }
        
        .header-edit-right button{
          width: 20%;
          height: 30px;
          border-radius: 10px;
          border: none;
          margin: 0 5px;
          cursor: pointer;
        }
  
          .header-edit-right button:focus{
            outline: none;
          }
  
  .customer-sign-up-page{
    min-height: 100vh;
  }
  
    #datePickerSearch{
      border: none;
      width: 20%;
      padding: 2px 5px;
    }
      #datePickerSearch:focus{
        outline: none;
      }
  
    .date-picker button{
      padding: 3px 10px;
      margin-left: 8px;
      font-weight: bold;
    }
  
      .date-picker button:focus{
        outline: none;
      }
  
    .data-viewer h2{
      margin: 4rem 0 2rem 0;
      color: #fff;
    }
  
    .data-viewer button{
      margin-top: 2rem;
      padding: 10px 20px;
      background-color: #30a76b;
      border: none;
      font-weight: bold;
      font-size: 16px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
  
      .data-viewer button:focus{
        outline: none;
      }
  
      .customer-event-area{
        display: flex;
        justify-content: space-evenly;
        margin: 1rem auto;
        width: 50%;
        color: #fff;
      } 
  
        .customer-event-area p{
          text-align: start;
          width: 100%;
        }


@media only screen and (max-width: 1024px) {
  .headersec{
    display: block;
  }

  .headerimg{
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .header-edit{
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .user-navbar{
    flex-direction: column;
    justify-content: space-between;
    min-height: 60px;
    width: 100%;
  }

  .customer-container{
    width: 100%;
  }

  .header{
    width: 100%;
    min-height: 700px;
  }

  .event,
  .product{
    flex-direction: column;
    width: 96%;
  }

    .image-div{
      margin-bottom: 1rem;
    }

    .details-div{
      padding: 0;
    }

    .eventinner,
    .productinner{
      flex-direction: column;
      width: 100%;
    }

  .add-edit-container{
    width: 96%;
  }

  .section-name{
    font-size: 1.5rem;
  }

  #datePickerSearch{
    width: 70%;
  }

  .customer-event-area{
    flex-direction: column;
  } 
}


        