.portfolio-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #portfolio-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        letter-spacing: 1.2px;
        font-family: "Roboto", sans-serif;
    }

    .portfolio-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .portfolio-section h2{
            font-size: 2.5rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;
        }

        .portfolio-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

    .portfolio-loop{
        width: 100%;
        margin: 2rem 0;
    }

        .grid-container {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(auto-fill, 250px);
            grid-gap: 30px 30px;
            padding: 10px;
            justify-content: center;
        }
            .grid-item {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }

@media only screen and (max-width: 945px) {
    .portfolio-section h2{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 768px) {
    .portfolio-section{
        width: 95%;
        padding: 1rem;
    }

    .portfolio-section h2{
        font-size: 2.3rem;
    }
}