.contact-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

    #contact-page-h1{
        margin: 2rem auto 1rem auto;
        color: #fff;
        font-size: 4rem;
        font-family: "Roboto", sans-serif;
    }

    .contact-section{
        width: 65%;
        padding: 1rem 2rem;
        border-radius: 20px;
        box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.7);
        background-color: rgb(41, 39, 39);
        color: #fff;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

        .contact-section h2{
            font-size: 3rem;
            margin-bottom: 1rem;
            font-family: "Roboto", sans-serif;
        }

        .contact-section p{
            margin: 0.3rem 0;
            font-size: 1.1rem;
            line-height: 1.5;
            letter-spacing: 0.6px;
            font-family: "Roboto", sans-serif;
        }

        .contact-section div{
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            width: 100%;
        }

        .contact-section input,
        .contact-section textarea{
            border: none;
            margin: 10px auto;
            height: 40px;
            border-radius: 10px;
            padding: 5px 10px;
            width: 80%;
            vertical-align: middle;
        }

        #contact-message{
            height: 100px;
        }

            .contact-section input:focus,
            .contact-section textarea:focus{
                outline: none;
            }

        #contact-btn{
            margin: 1rem auto 0 auto;
            padding: 10px 20px;
            width: 80%;
            background-color: cadetblue;
            border: none;
            border-radius: 20px;
            font-size: 0.9rem;
            color: #fff;
            letter-spacing: 1.2px;
            font-family: "Roboto", sans-serif;
            cursor: pointer;
        }

            #contact-btn:hover{
                background-color: rgb(127, 198, 201);
                color: rgb(20, 20, 20);
                font-weight: bold;
            }

            #contact-btn:focus{
                outline: none;
            }

        .contact-quote-message{
            width: 100%;
            text-align: center;
        }

@media only screen and (max-width: 768px) {
    .contact-section{
        width: 95%;
        padding: 1rem;
    }

    .contact-section h2{
        font-size: 2.3rem;
    }

    .contact-section input,
    .contact-section textarea{
        width: 100%;
    }

    .contact-section-btns{
        display: block;
    }

    .contact-section-btns button{
        width: 100%;
    }
}