*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sign-in-container{
    height: 88vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

    .sign-in-popup{
        width: 50%;
        height: 60vh;
        background-color: rgb(41, 39, 39);
        border-radius: 20px;
        padding-top: 2rem;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
    }

        .sign-in-popup h1,
        .sign-in-popup h4{
            color: #fff;
        }

        .form-sign-in{
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

            .form-sign-in button{
                margin-top: 1rem;
                padding: 8px 20px;
                border: none;
                border-radius: 10px;
                color: #fff;
                font-weight: bold;
                font-family: "Lato", sans-serif;
                font-size: 18px;
                letter-spacing: 1px;
                background-color: rgb(65, 65, 65);
                cursor: pointer;
            }

            .form-sign-in button:focus{
                outline: none;
            }


            .form-field{
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
            }

                .form-field label{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: #fff;
                    font-weight: bold;
                    font-size: 20px;
                    font-family: "Lato", sans-serif;
                    letter-spacing: 1px;
                    white-space: nowrap;
                    padding-bottom: 10px;
                }

                .form-field input{
                    height: 30px;
                    border: none;
                    padding: 5px 10px;
                    border-radius: 5px;
                }

                    .form-field input:focus{
                        outline: none;
                    }

        .error-message{
            color: #fff;
            margin-bottom: 20px;
        }

        .forgot-first{
            display: flex;
            flex-direction: column;
        }

        .forgot-pass{
            background-color: transparent;
            margin: 0 auto;
            margin-bottom: 10px;
            width: 15%;
            color: #fff;
            border: none;
            white-space: nowrap;
            cursor: pointer;
        }   

            .forgot-pass:focus{
                outline: none;
            } 

.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
}

    .modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;
        margin: 0 auto;
        padding: 40px 20px;
        background: #fff;
        border-radius: 10px;
        text-align: center;
    }

        .modal p{
            margin-top: 1rem;
            font-weight: bold;
        }

        .modal button{
        color: #444;
        border-color: #444;
        font-weight: bold;
        margin-top: 20px;
        }
    
        .modal h4{
        color: #444;
        font-weight: bold;
        padding-bottom: 5px;
        }

        .steps{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            width: 100%;
        }

        .reset-inputs
        {
            margin-top: 1rem;
            width: 70%;
            border: none;
            height: 30px;
            font-weight: bold;
            border-radius: 5px;
            padding: 5px 10px;
            box-shadow: 1px 1px 4px rgba(10, 10, 10, 0.7);
        }

            .reset-inputs:focus
            {
                outline: none;
            }

        .pass-reset-btns{
            padding: 10px 20px;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

            .pass-reset-btns:focus{
                outline: none;
            }

@media only screen and (max-width: 768px) {
    .sign-in-popup{
        width: 90%;
        height: 80%;
    }

    .form-field{
        width: 90%;
    }

    .form-sign-in{
        height: 60%;
    }

    .form-sign-in button{
        width: 90%;
        margin-bottom: 1rem;
    }

    .forgot-pass{
        width: 100%;
        margin-bottom: 20px;
    }
}